.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  position: relative;
  margin-bottom: 4em;
}

.loaderMargin {
  margin: auto;
}

.videoControlBtn {
  position: relative;
}

.react-multi-carousel-track>li {
  overflow: hidden !important;
  background-color: #0ff;
}

.testimonialSlide {
  display: flex;
  flex-direction: row;
  max-height: 500px;
  min-height: 500px;
}

.testimonialSliderTrack {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s;
}

.slideInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: var(--bmd-font-Primary);
  font-weight: var(--bmd-IvyPresto-regular);
  justify-content: center;
  margin-right: 5rem;
  max-width: 0;
  overflow: hidden;
  transition: max-width 0.25s, opacity 0.5s;
  width: 800px;
  max-width: 800px;
  overflow: visible;
}

.slideInfoWidth {
  width: 800px;
}

.slideInfoQuote {
  color: #000;
  font-size: 36px;
  margin-bottom: 1rem;
}

.slideInfoAuthor {
  color: #37246b;
  font-family: var(--bmd-font-Secondary);
  font-size: 18px;
}

.headingContainer {
  font-family: var(--bmd-font-Secondary);
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1380px;
  width: 100%;
}

.headingContainer h1 {
  font-family: var(--bmd-font-Primary);
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
  padding-bottom: 10px;
}

.playButton {
  background-color: #000;
  border-radius: 6px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  height: 60px;
  left: 50%;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s;
  width: 90px;
  z-index: 3;
}

.slideVideo {
  cursor: pointer;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  position: relative;
  transform: scale(0.74);
  transform-origin: top center;
  transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  width: 100%;
  max-width: 374px;
  margin: auto;
}

.customBtn {
  outline: 0;
  color: red;
  width: 50px !important;
  background-color: #fff;
  display: grid;
  padding-left: center;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 9;
  opacity: 0.7;
  cursor: pointer;
  line-height: 1.2em;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.slideVideo:hover .playerHolder {
  opacity: 1;
}

.ButtonGroup {
  position: absolute;
  bottom: 15px;
  display: flex;
  left: 40%;
  /* height: 17px; */
  width: 100%;
}

.dFlex {
  align-items: center;
  bottom: 80px;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  z-index: 2;
}

.ButtonGroup p,
.dFlex p {
  word-wrap: normal;
  color: transparent;
  content: none;
  display: block;
  font-size: 0;
  height: 9px;
  opacity: 0.7;
  padding: 24px 0;
  position: relative;
  transition: opacity 0.4s;
  width: 53px;
  margin: auto 5px;
  cursor: pointer;
}

.ButtonGroup p:before,
.dFlex p:before {
  background-color: #000;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 48%;
}

.ButtonGroup p:after,
.dFlex p:after {
  background: linear-gradient(135deg, #000 50%, hsla(0, 0%, 100%, 0) 0);
  content: "";
  height: 10px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  width: 10px;
}

.ButtonGroup p:nth-child(2),
.dFlex p:nth-child(2) {
  transform: rotate(180deg);
}


@media (min-width: 1440px) and (max-width: 1920px) {

  .ButtonGroup p,
  .dFlex p {
    font-size: unset;
    height: unset;
    padding: unset;

  }
}

@media screen and (max-width: 1400px) {
  .container {
    max-width: 900px;
  }

  .headingContainer {
    margin: 1rem 0 40px 2rem;
  }

  .slideInfo {
    margin-right: 2rem;
    width: 600px;
    max-width: 600px;
  }

  .slideInfoWidth {
    width: 600px;
  }
}

@media screen and (max-width: 1200px) {
  .slideInfo {
    margin-right: 4ch;
    width: 500px;
    max-width: 500px;
  }

  .slideInfoWidth {
    width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 500px;
  }

  .testimonialSlide {
    flex-direction: column-reverse;
  }

  .testimonialSlide {
    min-height: unset;
  }

  .slideInfoAuthor {
    display: flex !important;
    justify-content: center !important;
    margin: 0 0 37px 0 !important;
  }

  .ButtonGroup {
    left: 0%;
    justify-content: center;
  }

  .slideVideo {
    /* transform: unset; */
    margin: 0 auto 20px;
  }

  .slideInfo {
    max-height: 500px;
  }

  .slideInfoQuote {
    font-size: 26px;
    line-height: 32px;
    min-height: 100px;
    overflow: hidden;
    text-align: center;
    margin: 0 0 20px 0;
  }

  .dFlex {
    right: -75px;
  }
}

@media screen and (max-width: 500px) {

  .ButtonGroup {
    bottom: 0px;
  }
  .slideInfo {
    width: 320px;
    margin: auto;
  }

  .slideInfoWidth {
    width: 300px;
  }

  .main_container {
    margin-top: 4em;
  }

  .dFlex {
    position: relative;
    bottom: 0;
  }

  .headingContainer {
    margin-bottom: 4em;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .headingContainer h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  .videoEmbbed1 {
    height: auto;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;
    height: 100%;
    width: 57%;
    /* object-fit: cover; */
    display: flex;
    margin: 0 auto 20px auto;
  }

  .slideVideo {
    transform: unset;
    margin: 0 auto 0px auto !important;
    /* z-index: 41; */
  }

  .videoEmbbed1 {
    max-height: 250px;
    height: 183px !important;
  }
}

@media screen and (max-width: 420px) {
  .slideVideo {
    width: 280px;
  }

  .slideInfoQuote {
    font-size: 20px;
    line-height: 28px;
  }

  .slideInfoQuote p {
    margin-inline: 43px;
  }

  .headingContainer p {
    font-size: 14px;
  }
}

@media screen and (max-width: 321px) {
  .headingContainer h1 {
    font-size: 26px;
    line-height: 34px;
  }

  .headingContainer p {
    font-size: 12px;
  }
}

.playerHolder {
  background-color: #000;
  border-radius: 6px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  height: 60px;
  left: 50%;
  opacity: 0.6;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  transition: opacity 0.4s;
  width: 90px;
  z-index: 3;
}

.playerHolder:before {
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  border-width: 15px 0 15px 26px;
  content: "";
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.holder:hover .playerHolder {
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .playerHolder {
    top: 50%;
  }
}